<script setup>
import { onMounted, computed } from "vue";
import {
  FETCH_MY_POLICIES_ACTION,
  GET_MY_POLICIES,
} from "@/store/modules/USER/constants";
import { useStore } from "vuex";
import moment from "moment";
// import tableOptionsDropdown from "@/components/ui/baseComponents/tableOptionsDropdown.vue";
// import VuePdfEmbed from "vue-pdf-embed";

const store = useStore();

// const isLoading = ref(false);
// const pageCount = ref(0);
// const pdfSource = ref("");
// const page = ref(1);

// const viewAction = (event) => {
//   pdfSource.value = event.attachment;
// };

// const handleDocumentRender = () => {
//   isLoading.value = false;
//   pageCount.value = this.$refs.pdfRef.pageCount;
// };

// const dropdownOptions = [
//   {
//     type: "action",
//     label: "View",
//     action: viewAction,
//   },
// ];

onMounted(async () => {
  await fetchPolicies();
});

const fetchPolicies = async () => {
  await store.dispatch(`User/${FETCH_MY_POLICIES_ACTION}`);
};

const myPoliciesData = computed(() => {
  return store.getters[`User/${GET_MY_POLICIES}`];
});

var convertedTime = (data) => {
  return moment(data).format("DD/MM/YYYY");
};

// const tableHeader = [
//   { title: "Date", prop: "created_at", id: "date" },
//   {
//     title: "Roles",
//     prop: "roles",
//     id: "role",
//   },
//   {
//     title: "Type",
//     prop: "type",
//     id: "type",
//   },

//   {
//     title: "Actions",
//     prop: "user",
//     id: "actions",
//   },
// ];
</script>
<template>
  <div class="flex flex-col flex-1 p-2">
    <div class="flex-1 overflow-y-auto">
      <p class="text-gray-600 font-medium text-xl px-3">
        User's Practice Policies:
      </p>
      <div
        class="
          w-10/12
          md:w-7/12
          flex flex-col
          m-auto
          border-t-2 border-r-2 border-l-2
          rounded-b-l rounded-t-xl
          px-2
        "
        v-if="myPoliciesData?.data?.policies?.data?.length > 0"
      >
        <div
          v-for="(policy, index) in myPoliciesData?.data?.policies?.data"
          :key="policy.id"
          class="flex justify-between flex-nowrap text-grey border-b-2 py-3"
        >
          <div class="flex w-1/2 md:w-1/3 items-center pl-2">
            {{ index + 1 }} -
            <span class="material-icons text-3xl text-red">
              picture_as_pdf
            </span>
            <!-- <a :href="policy.attachment" target="_blank"
            ><p class="pl-2">{{ policy.name }}</p></a
          >-->
            <router-link
              :to="{
                name: 'us-policy',
                params: { policyId: policy.id },
                query: { policy: policy.attachment },
              }"
            >
              <p class="pl-2">{{ policy.type }}</p></router-link
            >
          </div>
          <div class="flex w-1/3 items-center">
            <p class="hidden sm:block">
              Uploaded:
              <strong>{{ convertedTime(policy.created_at) }}</strong>
            </p>
          </div>
        </div>
      </div>
      <div
        class="
          w-10/12
          md:w-7/12
          flex flex-col
          m-auto
          border-t-2 border-r-2 border-l-2
          rounded-b-l rounded-t-xl
          px-2
        "
        v-else
      >
        <div class="flex justify-between flex-nowrap text-grey border-b-2 py-3">
          No Policies Found.
        </div>
      </div>
    </div>
  </div>
</template>
